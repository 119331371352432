import React from 'react';

function ErrorPage() {
  
  

  return (
    <div>
        <h2>Session Not Started Yet Ask your Administrator</h2>
    </div>
  );
}

export default ErrorPage;
