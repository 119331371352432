import React, { useState } from 'react';
import './Style/Login.css';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [rolls, setRoll] = useState(null);

  const handleLogin = async () => {
    try {
      const response = await fetch('https://scannerst.pro/Components/Connection/Login.php', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      const data = await response.json();
        // Check if there is an error in the server response
        if (data.status === 'error') {
          // Set the error message with the user's first name
          setErrorMessage(data.message);
        } else {
          // Clear any previous error messages
          setErrorMessage('');
        }
      if (data.success) {
        setRoll(data.roll);

        if (rolls && rolls.userRoll === 'Admin') {
          console.log(rolls.userRoll);
          window.location.href = 'https://scannerst.pro/Session';
        } else {
          window.location.href = 'https://scannerst.pro/';
        }

      } 
      if(!data.success) {
        setError('Invalid username or password');
      }
    } catch (error) {
      console.error('Error logging in:', error);
    }
  };

  return (
    <div className='Loginpage'>
       {errorMessage && (
        <div className="error-popup">
          <p>{errorMessage}</p>
          <button onClick={() => setErrorMessage('')}>Close</button>
        </div>
      )}
      <div className='Loginheader'></div>
      <div className='Login'>
        <h2>Login</h2>
        <div>
          <label htmlFor='username'>Username:</label>
          <input
            type='text'
            id='username'
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor='password'>Password:</label>
          <input
            type='password'
            id='password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        {error && <p className='error'>{error}</p>}
        <button className='loginbtn' onClick={handleLogin}>
          Login
        </button>
      </div>
      <div className='Loginfooter'></div>
    </div>
    
  );
}

export default Login;
