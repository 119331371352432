import './Style/Footer.css';
import React from 'react';

function Footer() {
  return (
    <footer className="footer">
      <p>&copy; 2023 / 2024</p>
    </footer>  
  );
}

export default Footer;
