import React from 'react';
import Barcoder from './BarcoderScan';
import LastAdded from './LeatestAdd';

function Scan() {
  
  

  return (
    <>
      <Barcoder />
      <LastAdded/>
    </>
  );
}

export default Scan;
