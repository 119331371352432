import React, { useState, useEffect } from 'react';
import '../../Style/Barcode.css';
import './Style/Card.css';
import CheckIcon from '../../Assest/Images/check.svg';
import NoCheckIcon from '../../Assest/Images/r_x.svg';
import CloseMenuIcon from '../../Assest/Images/b_x.svg';

const AdminSearch = ({ onInsertionComplete }) => {
  const [barcode, setBarcode] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [isCooldown, setIsCooldown] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSearched, setIsSearched] = useState(false);
  const [searched, setSearched] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const openManuallyWindow = () => {
    setIsOpen(true);
    setQuantity('');
  };

  const closeManuallyWindow = () => {
    setIsOpen(false);
    setQuantity(1);
    setBarcode('');
  };

  const handleScanComplete = (option) => {
    console.log('Scanned Barcode:', barcode);

    // Send barcode data to the server
    if(option === 'Manually'){
      sendManuallyBarcode();
    }else{
      sendBarcodeToServer();
    }
    

    // Set cooldown
    setIsCooldown(true);
    setTimeout(() => {
      setBarcode('');
      setIsCooldown(false);

      // Call the onInsertionComplete callback when barcode insertion is complete
      if (onInsertionComplete) {
        onInsertionComplete();
      }
    }, 500);
  };

  const sendBarcodeToServer = () => {
    // Send barcode data to the server using fetch or any other method
    fetch('https://scannerst.pro/Components/Admin/AdminComponents/Connection/SearchBarcode.php', {
      method: 'POST',
      credentials: 'include', // Include credentials
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ barcode }),
    })
      .then(response => response.json())
      .then(data => {
        console.log('Server response:', data);

        // Check if there is an error in the server response
        if (data.status === 'error') {
          // Set the error message with the user's first name
          setErrorMessage(data.message);
        } else {
          // Clear any previous error messages
          setErrorMessage('');
        }
        
        if(data.success){
          setSearched(data.user);
          setIsSearched(true);
        }
      })
      .catch(error => {
        console.error('Error sending barcode to server:', error);
        // Set an error message for network or server errors
        setErrorMessage('An error occurred while communicating with the server.');
      });
  };

  const sendManuallyBarcode = () => {
    // Send barcode data to the server using fetch or any other method
    fetch('https://scannerst.pro/Components/Admin/AdminComponents/Connection/InsertBarcode.php', {
      method: 'POST',
      credentials: 'include', // Include credentials
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ barcode, quantity }),
    })
      .then(response => response.json())
      .then(data => {
        console.log('Server response:', data);

        // Check if there is an error in the server response
        if (data.status === 'error') {
          // Set the error message with the user's first name
          setErrorMessage(data.message);
        } else {
          // Clear any previous error messages
          setErrorMessage('');
        }
      })
      .catch(error => {
        console.error('Error sending barcode to server:', error);
        // Set an error message for network or server errors
        setErrorMessage('An error occurred while communicating with the server.');
      });
  };

  const handleAddManually = () => {
    handleScanComplete("Manually");
    closeManuallyWindow();
  };

  const handleKeyPress = (event) => {
    if (!isCooldown) {
      if (/^[a-zA-Z0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]$/.test(event.key)) {
        setBarcode((prevBarcode) => prevBarcode + event.key);
      } else if (event.key === 'Enter') {
        handleScanComplete("Search");
      }
    }
  };


  useEffect(() => {
    document.addEventListener('keypress', handleKeyPress);

    return () => {
      document.removeEventListener('keypress', handleKeyPress);
    };
  }, [barcode, isCooldown, isOpen, handleScanComplete]);

  return (
    <div className='scanpagediv'>
      {errorMessage && (
        <div className="error-popup">
          <p>{errorMessage}</p>
          <button onClick={() => setErrorMessage('')}>Close</button>
        </div>
      )}

      <div className='barcodesesction'>
        <div className='barcode39'>{barcode}</div>
      </div>

      <>
        {isOpen ? (
          <>
            <div className='overlay' onClick={closeManuallyWindow}></div>
            <div className='Add-Manually-Window'>
              <h1>Add Manually</h1>
              <button className='CloseWindow' onClick={closeManuallyWindow}><img src={CloseMenuIcon} alt='Close Manu Icon'></img></button>
                <p>This Will Add the Barcode Directly to the Session Table, Use it if the barcode is missing</p>
                <input
                  type='text'
                  name="barcode"
                  value={barcode}
                  onChange={(e) => setBarcode(e.target.value)}
                  placeholder='Barcode'
                />
                <input
                  type='number'
                  name="qty"
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                  placeholder='QTY'
                />
                <button id="addButton" className='bluebtn' onClick={handleAddManually}>
                  Add
                </button>
            </div>
          </>
        ) : <button className='bluebtn' onClick={openManuallyWindow}>
          Add Barcode
        </button>}<br />
      </>
      
      <>
       {isSearched ? 
       <div className='searchedDiv'>
        <table>
          <tbody>
            <tr>
              <td>Barcode  </td>
              <td>{searched.barcode}</td>
            </tr>
            <tr>
              <td>Quantity  </td>
              <td>{searched.qty}</td>
            </tr>
            <tr>
              <td>User  </td>
              <td>{searched.UserFullname}</td>
            </tr>
            <tr>
              <td>Status  </td>
              <td>{searched.qty_difference === '0' ? <img src={CheckIcon}></img> : <img src={NoCheckIcon}></img>}</td>
            </tr>
          </tbody>
        </table>
       </div> 
      : <p>Search to fetch result ...</p>}
      </>
    </div>
  );
};

export default AdminSearch;
