import './Style/Header2.css';
import { Link , useLocation  } from 'react-router-dom';
import  {React, useState} from 'react';
import MenuIcon from './Assest/Images/menu.svg';
import CloseMenuIcon from './Assest/Images/w_x.svg';


function Header() {
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(false);

  const OpenMenu = () => {
    setIsOpen(true);
  };

  const CloseMenu = () => {
    setIsOpen(false);
  };

  const handleLogout = async () => {
    try {
      const response = await fetch('https://scannerst.pro/Components/Connection/Logout.php', {
        method: 'POST',
        credentials: 'include',
      });
  
      if (response.ok) {
        // Assuming a successful logout returns an empty response
        // Redirect or perform any additional actions after successful logout
        window.location.href = '/'; // Change '/login' to your actual login page
      } else {
        console.error('Error logging out:', response.statusText);
      }
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <>
      <header className="navbar">
          <h2>Scanner ST</h2>
          <div>
          <Link to="./Codes" className={location.pathname === "/Codes" ? "activeRoute" : "navlink"} >Codes</Link>
          <Link to="./" className={location.pathname === "/" ? "activeRoute" : "navlink"} >Scan</Link>
          <Link to="./Profile" className={location.pathname === "/Profile" ? "activeRoute" : "navlink"} >Profile</Link>
          <Link to="./Logout"  onClick={() => { CloseMenu(); handleLogout(); }}  className={location.pathname === "/Logout" ? "activeRoute" : "navlink lastlink"} >Logout</Link>
          </div>
          </header>
      <header className='mobilenavbar'>
         <h1>Scanner ST</h1>
         <button onClick={OpenMenu}><img src={MenuIcon} alt='open'></img></button>
         {isOpen ? ( 
         <>
         <div className='overlay' onClick={CloseMenu}></div>
         <div className='mobilenavminu'>
            <h1> Menu </h1>
            <button onClick={CloseMenu}><img src={CloseMenuIcon} alt='close'></img></button>
            <Link to="./" onClick={CloseMenu} className={location.pathname === "/" ? "activeRoute" : "navlink"} >Scan</Link>
            <Link to="./Codes" onClick={CloseMenu} className={location.pathname === "/Codes" ? "activeRoute" : "navlink"} >Codes</Link>
            <Link to="./Profile" onClick={CloseMenu} className={location.pathname === "/Profile" ? "activeRoute" : "navlink"} >Profile</Link>
            <Link to="./Logout"  onClick={() => { CloseMenu(); handleLogout(); }}  className={location.pathname === "/Logout" ? "activeRoute" : "navlink lastlink"} >Logout</Link>
         </div>
         </>
         
          ) : null}
      </header>
    </>
    
  );
}

export default Header;
